<template>
    <div>
        <div class="list">
            <ul>
                <li @click="goinfo('pharmacyInfo')">
                    <img src="../../assets/img/banner.png" alt="">
                    <div class="con">
                        <h4>同仁堂大药房(光谷大道店)</h4>
                        <p>在线咨询、急速发货、正品授权</p>
                    </div>
                </li>
                <li @click="goinfo('pharmacyInfo')">
                    <img src="../../assets/img/banner.png" alt="">
                    <div class="con">
                        <h4>同仁堂大药房(光谷大道店)</h4>
                        <p>在线咨询、急速发货、正品授权</p>
                    </div>
                </li>
                <li @click="goinfo('pharmacyInfo')">
                    <img src="../../assets/img/banner.png" alt="">
                    <div class="con">
                        <h4>同仁堂大药房(光谷大道店)</h4>
                        <p>在线咨询、急速发货、正品授权</p>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    methods:{
        goinfo(names){
            this.$router.push({name: names})
        },
    }
}
</script>

<style lang="less" scoped>
.list { 
    li {
        display: flex; justify-content: center; align-items: center; flex-direction: row;
        width: 343px; height: 112px; background: #fff; border-radius: 4px; margin: 0 auto; margin-bottom: 8px;
        img { width: 80px; height: 80px; border-radius: 2px;}
        .con { width: 225px; height: 80px; margin-left: 16px;
            h4 { font-size: 16px; color: #2D2D2D; font-weight: bold; line-height: 18px} 
            p { margin-top: 16px; font-size: 12px; color: #1DAEFC; line-height: 18px;} 
        }  
    }
}
</style>